export const DOMAIN_MAP = {
  en: {
    domain: "www.rorostweed.com",
    defaultLocale: "en",
  },
  no: {
    domain: "www.rorostweed.no",
    defaultLocale: "no",
  },
};

export const DEFAULT_LOCALE = "en";

/** @type {import('next').NextConfig} */
const i18nConfig = {
  i18n: {
    locales: ["en", "no"],
    defaultLocale: DEFAULT_LOCALE,
    domains: Object.values(DOMAIN_MAP),
    localeDetection: false,
  },
  react: { useSuspense: false }, //this line is important
};

export const DOMAINS = Object.values(DOMAIN_MAP);

export default i18nConfig;

import type { ISbStoryData } from "@storyblok/react";
import { StoryblokComponent, useStoryblokState } from "@storyblok/react";
import type { GetStaticProps, InferGetStaticPropsType, NextPage } from "next";
import { useTranslations } from "next-intl";
import { NextSeo } from "next-seo";

import NotFound from "@/components/NotFound";
import { AlternatesHead } from "@/features/i18n/I18nHead";
import { fetchServerTranslations } from "@/i18n.server";
import getStory from "@/lib/storyblok/getStory";

const Home: NextPage<InferGetStaticPropsType<typeof getStaticProps>> = ({
  story: initialStory,
}) => {
  const t = useTranslations();
  const story = useStoryblokState(initialStory, {}) as ISbStoryData;

  if (!story.content || !story) {
    return <NotFound />;
  }

  return (
    <>
      <AlternatesHead
        value={{
          en: "/",
          no: "/",
        }}
      />
      <NextSeo
        title={story?.content?.metadata?.title || t("homepage")}
        description={
          story?.content?.metadata?.description || t("defaultDescription")
        }
      />
      <StoryblokComponent blok={story.content} />
    </>
  );
};

export const getStaticProps: GetStaticProps = async ({
  preview = false,
  locale = "en",
}) => {
  const slug = "home";
  const story = await getStory({
    slug,
    preview,
    locale,
  });

  return {
    props: {
      ...(await fetchServerTranslations(locale)),
      story: story ? story : "",
      preview,
    },
  };
};

export default Home;

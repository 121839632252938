import type { ISbStoryData } from "@storyblok/react";
import Head from "next/head";
// eslint-disable-next-line import/extensions
import { useRouter } from "next/router";

import type { Blok } from "@/bloks/Article";

// eslint-disable-next-line import/extensions
import { DOMAIN_MAP, DOMAINS } from "./i18n.config.mjs";

const sanitizeUrl = (url: string) => {
  return url.replace("/info-pages", "");
};

const Alternates = ({ story }: { story: ISbStoryData<Blok> | null }) => {
  const { locale } = useRouter();
  if (!story) return null;

  const defaultLink =
    story.lang !== "default"
      ? DOMAINS?.find((d) => d.defaultLocale !== story.lang)
      : undefined;

  const foundDomain = DOMAINS?.find((d) => d.defaultLocale === locale);

  return (
    <Head>
      {foundDomain && (
        <link
          rel="canonical"
          href={sanitizeUrl(`https://${foundDomain.domain}/${story.full_slug}`)}
        />
      )}
      {story.translated_slugs?.map((slug, i) => {
        const domain = DOMAINS?.find(
          (d) => d.defaultLocale === slug.lang
        )?.domain;
        const path = slug.path === "home" ? "" : slug.path;
        return (
          <link
            key={i}
            rel="alternate"
            href={sanitizeUrl(`https://${domain}/${path}`)}
            lang={slug.lang}
            hrefLang={slug.lang}
          />
        );
      })}

      {defaultLink && (
        <>
          <link
            rel="alternate"
            lang={defaultLink.defaultLocale}
            href={sanitizeUrl(
              `https://${defaultLink.domain}/${story.default_full_slug}`
            )}
            hrefLang={defaultLink.defaultLocale}
          />
        </>
      )}
    </Head>
  );
};

export const AlternatesHead = ({
  value,
}: {
  value: Record<keyof typeof DOMAIN_MAP, string>;
}) => {
  const { locale } = useRouter();
  const foundDomain = DOMAINS?.find((d) => d.defaultLocale === locale);
  return (
    <Head>
      {foundDomain && (
        <link
          rel="canonical"
          href={`https://${foundDomain.domain}${
            value[locale as keyof typeof value]
          }`}
        />
      )}
      {Object.entries(value).map(([key, value]) => {
        const domain = DOMAIN_MAP[key as keyof typeof DOMAIN_MAP];
        if (!domain) return null;
        const href = value.startsWith("http")
          ? value
          : `https://${domain.domain}${
              value.startsWith("/") ? value : `/${value}`
            }`;
        return <link key={key} rel="alternate" href={href} hrefLang={key} />;
      })}
    </Head>
  );
};

const StoryHead = ({ story }: { story: ISbStoryData<Blok> | null }) => {
  if (!story) {
    return null;
  }
  return (
    <>
      <Alternates story={story} />
    </>
  );
};

export const I18nHead = ({ story }: { story: ISbStoryData<Blok> | null }) => {
  return (
    <>
      <StoryHead story={story} />
    </>
  );
};
